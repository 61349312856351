.language-dropdown-h5 {
    position: absolute;
    display: inline-block;
    right: 15px;
    top: 20px;
}

.language-dropdown {
    position: absolute;
    display: inline-block;
    right: 10px;
    top: 50px;
}

.dropdown-header {
    background: none;
    border: none;
    color: #fff;
    font-size: 12pt;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
}

.dropdown-header .arrow {
    margin-left: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    transition: transform 0.3s ease;
}

.dropdown-header .arrow.down {
    transform: rotate(0deg);
}

.dropdown-header .arrow.up {
    transform: rotate(180deg);
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #393939;
    border-radius: 5px;
    overflow: hidden;
    z-index: 1000;
    width: 100%;
}

.dropdown-item {
    color: #fff;
    font-size: 12pt;
    padding: 10px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #555;
}