.download-button {
    background-color: #694BFF;
    width: 160pt;
    height: 40pt;
    font-family: 'PingFang SC-Medium', sans-serif;
    font-size: 14pt;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36pt;
}

.download-button-h5 {
    background-color: #694BFF;
    width: 110pt;
    height: 33pt;
    font-family: 'PingFang SC-Medium', sans-serif;
    font-size: 11pt;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36pt;
}