.home-logo {
  // width: 145px;
  height: 24px;
}

.purchase-button-container {
  display: flex;
  align-items: center; /* Vertically center the button */
}

.w-purchase-button {
  background: linear-gradient(90deg, #FC9300, #BD00FC);
  width: 140pt;
  height: 31pt;
  font-family: 'PingFang SC-Medium', sans-serif;
  /* 设置字体为PingFang SC-Medium */
  font-size: 14pt;
  display: flex;
  /* 使用flex布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  border: none;
  /* 去除边框 */
}

.w-purchase-button:hover {
  /* 可选：添加悬停效果 */
  filter: brightness(1.2);
}

.w-try-button {
  // background: linear-gradient(90deg, #FC9300, #BD00FC);
  background: white;
  width: 128pt;
  height: 30pt;
  font-family: 'PingFang SC-Medium', sans-serif;
  /* 设置字体为PingFang SC-Medium */
  font-size: 14pt;
  font-weight: 800;
  display: flex;
  /* 使用flex布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  border: none;
  /* 去除边框 */
  border-radius: 36pt;
  position: relative; /* 使伪元素定位相对 */
  overflow: hidden; /* 隐藏超出按钮范围的内容 */
}

.w-try-button::before {
  content: var(--button-text); /* 确保伪元素内容与按钮文字一致 */
  position: absolute; /* 绝对定位 */
  top: 0; left: 0; /* 使伪元素填满按钮 */
  width: 100%; height: 100%;
  display: flex; /* 使用 flex 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  font-family: inherit; /* 继承字体 */
  font-size: inherit; /* 继承字体大小 */
  background: linear-gradient(90deg, #FC9300, #BD00FC); /* 渐变色 */
  background-clip: text; /* 使背景裁剪到文字 */
  color: transparent; /* 隐藏文字的默认颜色 */
}

.w-try-button span {
  position: relative; /* 使文字在伪元素上方 */
}

.w-try-button:hover {
  /* 可选：添加悬停效果 */
  filter: brightness(1.2);
}

.w-home-bg {
  background-color: #212121;
}

.overview-img {
  max-width: 470px; /* 或使用其他适当的宽度 */
  width: 100%;
}

.w-banner-img {
  max-width: 450px; /* 或使用其他适当的宽度 */
  width: 100%;
}

.w-faq-item {
  width: 100% !important;
  border-bottom: 1px solid #363636;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.w-faq-item:last-child {
  border-bottom: none; /* 去除最后一个项的下边框 */
}

.w-faq-question {
  width: 100%;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  font-size: 14pt;
  color: #FFFFFF;
  text-align: left;
}

.w-faq-answer {
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  font-size: 14pt;
  color: #9699A1;
  text-align: left;
}

.module-container {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}