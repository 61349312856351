.carousel {
  width: 278px;
  height: 475px;
  overflow: hidden;
  border-radius: 36px;

  div {
    img {
      width: 100%;
      height: 100%;

    }
  }
}

.purchase-button {
  background: linear-gradient(90deg, #FC9300, #BD00FC);
  width: 100pt;
  height: 24pt;
  font-family: 'PingFang SC-Medium', sans-serif;
  /* 设置字体为PingFang SC-Medium */
  font-size: 12pt;
  font-weight: 400;
  display: flex;
  /* 使用flex布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  border: none;
  /* 去除边框 */
}

.purchase-button:hover {
  /* 可选：添加悬停效果 */
  filter: brightness(1.2);
}

.paypal-popup {
  background-color: #22242A;
  overflow-y: auto;
}

.buy-bg {
  background-color: #22242A;
  overflow-y: auto;
}

.paypal-popup-title {
  width: 111px;
  height: 28px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 23px;
  font-style: normal;
  text-transform: none;
}

.product-button {
  width: 275px;
  height: 50px;
  border-radius: 12px 12px 12px 12px;
  align-items: center;

  border: 2px solid transparent;
  transition: border-color 0.3s ease, color 0.3s ease;

  /* 初始状态下文字为白色 */
  color: #FFFFFF;
  border-color: #4d4e51;
}

.product-button.selected {
  border-color: #FC9300;
  color: #FC9300;
}

.select-payment-method {
  width: 180px;
  height: 20px;
  font-family: PingFang SC-Regular;
  font-weight: 500;
  font-size: 14px;
  color: #656669;
  font-style: normal;
  text-transform: none;
}

.flex {
  justify-content: center;
}

.close-button {
  width: 25px; /* 设置按钮宽度 */
  height: 25px; /* 设置按钮高度 */
  border-radius: 50%; /* 将按钮形状设为圆形 */
  border: 1px solid #656669; /* 设置按钮边框为灰色 */
  background-color: transparent; /* 设置背景颜色为透明 */
  color: #656669; /* 设置按钮内部叉号颜色为灰色 */
  font-size: 18px; /* 设置按钮内部叉号字体大小 */
  cursor: pointer; /* 设置鼠标样式为手型 */
}

.close-button:hover {
  background-color: #CCCCCC; /* 设置鼠标悬停时的背景颜色 */
  color: #FFFFFF; /* 设置鼠标悬停时内部叉号的颜色 */
}

.benefits-title {
  width: 179px;
  height: 68px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.benefits-body {
  width: 245px;
  height: 44px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 300;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.subscribe-benefits-title {
  width: 200px;
  height: 30px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.subscribe-benefits-body {
  width: 245px;
  height: 40px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 300;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.home-bg {
  background-color: #212121;
}

.home-title {
  height: 56px;
  font-family: Alibaba PuHuiTi;
  font-weight: 800;
  font-size: 38pt;
  color: #FFFFFF;
  text-align: center;
}

.home-subtitle {
  max-height: 80px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  font-size: 21pt;
  color: #FFFFFF;
  text-align: center;
}

.home-desc {
  height: 60px;
  font-family: Alibaba PuHuiTi;
  font-weight: 15;
  font-size: 11pt;
  color: #FFFFFF;
  text-align: center;
  line-height: 18pt;
}

.home-purchase-desc {
  height: 20px;
  font-family: Alibaba PuHuiTi;
  font-weight: 30;
  font-size: 11pt;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.9;
}

.banner-title {
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  font-size: 21pt;
  color: #FFFFFF;
  text-align: center;
}

.banner-desc {
  width: 355px;
  font-family: Alibaba PuHuiTi;
  font-weight: 15;
  font-size: 10pt;
  color: #FFFFFF;
  text-align: center;
  line-height: 18pt;
}

.banner-img {
  width: 300px;
  overflow: hidden;
  // border-radius: 36px;

  div {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.faq-title {
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  font-size: 19pt;
  color: #FFFFFF;
  text-align: center;
}

.faq-item {
  border-bottom: 1px solid #363636;
  padding-bottom: 2rem;
  padding-top: 2rem;
  // width: 338px;
  width: 95%;
}

.faq-item:last-child {
  border-bottom: none; /* 去除最后一个项的下边框 */
}

.faq-question {
  width: 100%;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  font-size: 11pt;
  color: #FFFFFF;
  text-align: left;
}

.faq-answer {
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  font-size: 11pt;
  color: #9699A1;
  text-align: left;
}

.rotate-180 {
  transform: rotate(180deg);
}

.try-button {
  // background: linear-gradient(90deg, #FC9300, #BD00FC);
  background: white;
  width: 100pt;
  height: 25pt;
  font-family: 'PingFang SC-Medium', sans-serif;
  /* 设置字体为PingFang SC-Medium */
  font-size: 12pt;
  font-weight: 800;
  display: flex;
  /* 使用flex布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  border: none;
  /* 去除边框 */
  border-radius: 36pt;
  position: relative; /* 使伪元素定位相对 */
  overflow: hidden; /* 隐藏超出按钮范围的内容 */
}

.try-button::before {
  content: var(--button-text); /* 确保伪元素内容与按钮文字一致 */
  position: absolute; /* 绝对定位 */
  top: 0; left: 0; /* 使伪元素填满按钮 */
  width: 100%; height: 100%;
  display: flex; /* 使用 flex 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  font-family: inherit; /* 继承字体 */
  font-size: inherit; /* 继承字体大小 */
  background: linear-gradient(90deg, #FC9300, #BD00FC); /* 渐变色 */
  background-clip: text; /* 使背景裁剪到文字 */
  color: transparent; /* 隐藏文字的默认颜色 */
}

.coinpal-button {
  background-color: #694BFF;
  width: 110pt;
  height: 33pt;
  font-family: 'PingFang SC-Medium', sans-serif;
  font-size: 11pt;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36pt;
}